import React from 'react';
import styled from 'styled-components';
import { graphql } from "gatsby";
import Layout from '../components/layout';
import Question from '../components/question';
import ColoredSeparator from '../components/colored-separator';
import Separator from '../components/separator';
import Crotchet from '../components/crotchet';
import Meta from '../components/meta';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
`;

const Title = styled.div`
  font-family: 'GLECB', 'GLECB2';
  font-size: 3rem;
`;

const Questions = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Group = styled.div`
  margin-bottom: 32px;
  width: 800px;
  max-width: 100%;

`;
const GroupTitle = styled.div`
  font-family: 'GLECB', 'GLECB2';
  font-size: 2rem;
  margin-bottom: 8px;
`;

const FAQ = ({ data }) => {
  const questions = data.allMarkdownRemark.edges.map(node => node.node);

  const orders = [
    'Livraison',
    'Retour',
    'Sécurité',
    'Divers'
  ];

  const toIndex = (group) => orders.indexOf(group);

  // Livraison -> Retour -> Sécurité -> Divers
  const questions2 = questions.reduce((acc, question) => {
    const groupIndex = toIndex(question.frontmatter.group);
    if (!acc[groupIndex]) {
      acc[groupIndex] = [];
    }
    acc[groupIndex] = [...acc[groupIndex], question]
    return acc;
  }, []);

  return (
    <Layout>
      <Meta title="FAQ"/>
      <ColoredSeparator />
      <Container>
        <Title>Foire aux questions</Title>
        <Title>FAQ</Title>
        <Separator size={64} />
        {questions2.map((questions, groupIndex) => {
          const category = orders[groupIndex];
          return (
            <Group>
              <GroupTitle>{category}</GroupTitle>
              <Questions>
                {questions.map(question => {
                  return <Question id={question.id} question={question.frontmatter.title} answer={question.html} />
                })}
              </Questions>
            </Group>
          );
        })}
      </Container>
      <Crotchet />
    </Layout>);
}

export default FAQ;

export const query = graphql`
      {
        allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(faq)/"}}) {
        edges {
        node {
        id
          html
      frontmatter {
        title
            group
          }
        }
      }
    }
  }
      `
import styled from 'styled-components';
import crotchet from "../assets/images/crotchet.png";

const Crotchet = styled.div`
  position: absolute;
  top: 70px;
  right: 0px;
  opacity: 0.16;
  background-image: url(${crotchet});
  width: 290px;
  height: 880px;
  
  @media(max-width: 950px){
    display: none;
  }
`;

export default Crotchet;
import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div``;


const QuestionContainer = styled.div`
  display: flex;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

const QuestionTitle = styled.div`
  font-family: 'GLECB', 'GLECB2';
  font-size: 1.8rem;
  margin-left: 8px;
`;

const Circle = styled.div`
  width: 8px;
  height: 8px;
  border: 2px solid black;
  border-radius: 50%;
`;

const Answer = styled.div`
  color: #5F5F5F;
  margin-left: 26px;
`;


const Question = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Container>
      <QuestionContainer onClick={() => setIsOpen(!isOpen)}>
        <Circle />
        <QuestionTitle>{question}</QuestionTitle>
      </QuestionContainer>
      {isOpen && <Answer dangerouslySetInnerHTML={{ __html: answer }} />}
    </Container>);
}

export default Question;